import React from "react";
import GoogleLogin from "react-google-login";
import banner1 from "../Assets/Business ethics-pana (1).svg";
import { FiSearch } from "react-icons/fi";
import minilogo from "../Assets/Path 1350.svg";
import SideBar from "../Component/SideBar";
import logo from "../Assets/1332.png";
import pdf from "../Assets/Group 32.png";
import dmcc_img from "../Assets/Group 328.png";
// import dubailogo from '../Assets/dubailogo.png'
import { BsLink45Deg } from "react-icons/bs";
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaList,
  FaFilePdf,
} from "react-icons/fa";
import { personalDocument } from "../store/Admin/action";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";

class IndividualDetailScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      password: "",
      email: "",
      errors: "",
      signIn: true,
      view: true,
      Compdata: "",
      verifiedCount: 0,
      mismatchCount: 0,
    };
  }
  componentDidMount() {
    console.log(this.props?.history?.location?.state?.data);
    this.props.personalDocument(
      this.props.history?.location?.state?.data?._id ?? ""
    );
  }

  componentDidUpdate(prev) {
    if (prev.companyDetail_err !== this.props.companyDetail_err) {
      console.log(prev.companyDetail_err);
      // console.log(this.props.companyDetail_err)
      // if(!localStorage.getItem('refresh_token')){
      //     this.props.history.push('/');
      // }
      // toast.error(this.props.companyDetail_err.response.data.message||this.props.companyDetail_err.response.data.Error||"Something went wrong");
    }

    if (prev.companyDetail !== this.props.companyDetail) {
      console.log(this.props.companyDetail?.data?.data);
      this.setState({
        Compdata: this.props.companyDetail?.data?.data,
      });

      Array.isArray(this.props.companyDetail?.data?.data) &&
        this.props.companyDetail?.data?.data.map((data) => {
          console.log(data);
          if (data?.isVerified) {
            this.setState((prevState) => ({
              verifiedCount: prevState.verifiedCount + 1,
            }));
          }

          if (data?.isMismatched) {
            this.setState((prevState) => ({
              mismatchCount: prevState.mismatchCount + 1,
            }));
          }
        });
    }
  }

  handleView = () => {
    this.setState({
      view: !this.state.view,
    });
  };

  testing = (data, data1) => {
    console.log(data);
    console.log(data1);

    sessionStorage.setItem("CertificateShowId", data);
    this.props.history.push({
      pathname: "/Certificate_Detail",
      state: {
        certificateId: data?.hash,
        certificateName: data1,
        data: data,
        isVerified: data?.isVerified,
        isMismatched: data?.isMismatched,
        isRejected: data?.isRejected,
        docType: "Personal",
      },
    });
  };

  render() {
    // console.log(this.props.history?.location?.state?.data)
    return (
      <div className="listed-page">
        <div className="lg-page-banner">
          <SideBar {...this.props} />
        </div>
        <div className="lg-page-form py-3">
          <div className="Detail-row-one">
            {/* <img src={logo}/> */}
            <div>
              <label>
                {this.props.history?.location?.state?.data?.username}
              </label>
              <p>Know your details in a quick overview,</p>
              <p id="label">
                Onboarded On{" "}
                {this.props.history?.location?.state?.data?.createdAt}
              </p>
              <a href="javascript:void(0)">
                {this.props.history?.location?.state?.data?.txID}
              </a>
            </div>
            <div className="div-block">
              <p></p>
              {/* <img src={dubailogo} style={{height: "100px", width:"140px", marginRight:"70px", paddingRight:"30px",}}/><br/> */}
              <p></p>
              {/* <label>{this.props.history?.location?.state?.data?._id}</label> */}
            </div>
          </div>
          <div className="row Detail-row-sec">
            <div className="col-md-3 offset-md-5">
              <div className="row">
                <div className="col-md-10">
                  <label>Total Number of Verified Documents</label>
                  <p>Documents has uploaded while Onboarding the company</p>
                </div>
                <div className="col-md-2">
                  <center>
                    {/* <label id="label-value" style={{color:'#F2BC4C'}}>{this.props?.history?.location?.state?.data?.verified_doc_Count}</label> */}
                    <label id="label-value" style={{ color: "#98CC34" }}>
                      {this.state?.verifiedCount}
                    </label>
                  </center>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="row">
                <div className="col-md-10">
                  <label>Mismatch Documents</label>
                  <p>
                    Document has mismatch while Blockchain based verification
                  </p>
                </div>
                <div className="col-md-2">
                  <center>
                    <label id="label-value" style={{ color: "red" }}>
                      0
                    </label>
                  </center>
                </div>
              </div>
            </div>
          </div>
          <div className="row third-row" id="third-row">
            <div className="top-head" style={{}}>
              <p>
                Documents Uploaded
                <br />
                <span>Complete the Onboarding Process .</span>
              </p>
              <a onClick={this.handleView}>
                <FaList />{" "}
                <span>{this.state.view ? "Block view" : "List view"}</span>
                <br />
                {/* <span>view all</span> <FaAngleDoubleRight/> */}
              </a>
            </div>
            {this.state.view == true ? (
              <div>
                <hr></hr>
                <div className="row">
                  <div className="col-md-1 col">
                    <center>
                      {/* <input type="checkbox"/> &nbsp;  */}
                      <p>S.No</p>
                    </center>
                  </div>
                  <div className="col-md-2 col">
                    <center>
                      <p>Document Name</p>
                    </center>
                  </div>
                  <div className="col-md-4 col">
                    <center>
                      <p>Blockchain ID</p>
                    </center>
                  </div>
                  <div className="col-md-2 col">
                    <center>
                      <p>Time Stamp</p>
                    </center>
                  </div>
                  <div className="col-md-1 col">
                    <center>
                      <p>Uploaded On</p>
                    </center>
                  </div>
                  <div className="col-md-2 col">
                    <center>
                      <p>Verification Status</p>
                    </center>
                  </div>
                </div>
                <hr></hr>
                <div
                  style={{
                    height: "270px",
                    overflowY: "scroll",
                    cursor: "pointer",
                  }}
                >
                  {Array.isArray(this.state.Compdata) &&
                    this.state.Compdata.map((each, index) => {
                      return (
                        <>
                          <div
                            className="row"
                            style={{ cursor: "pointer" }}
                            onClick={() => this.testing(each, each?.doc_name)}
                          >
                            <div className="col-md-1 col">
                              <center>
                                {/* <input type="checkbox"/> &nbsp;  */}
                                <p>{index + 1}</p>
                              </center>
                            </div>
                            <div className="col-md-2 col">
                              <center>
                                <p style={{ overflowWrap: "break-word" }}>
                                  {each?.doc_name?.replace(
                                    /([a-z])([A-Z])/g,
                                    `$1 $2`
                                  )}
                                </p>
                              </center>
                            </div>
                            <div className="col-md-4 col">
                              <center>
                                <p
                                  style={{
                                    color: "#0f878c",
                                    overflowWrap: "anywhere",
                                  }}
                                >
                                  {each?.hash}
                                </p>
                              </center>
                            </div>
                            <div className="col-md-2 col">
                              <center>
                                <p style={{ overflowWrap: "anywhere" }}>
                                  {each?.createdAt}
                                </p>
                              </center>
                            </div>
                            <div className="col-md-1 col">
                              <center>
                                <p style={{ color: "#1E93BE" }}>
                                  {each?.createdAt.slice(0, 10)}
                                </p>
                              </center>
                            </div>
                            <div className="col-md-2 col">
                              <center>
                                <p>
                                  <BsLink45Deg
                                    style={{
                                      color: each.isVerified
                                        ? "#438d27"
                                        : each.isMismatched
                                        ? "#cc7a00"
                                        : "#990000",
                                    }}
                                  />
                                  {each.isVerified
                                    ? "Verified"
                                    : each.isMismatched
                                    ? "Mismatched"
                                    : each.isRejected
                                    ? "Rejected"
                                    : "Not verified"}
                                </p>
                              </center>
                            </div>
                          </div>
                          <hr></hr>
                        </>
                      );
                    })}
                </div>
              </div>
            ) : (
              <div
                className="row"
                style={{ height: "320px", overflowY: "scroll" }}
              >
                {console.log(this.state.Compdata)}
                {Array.isArray(this.state.Compdata) &&
                  this.state.Compdata.map((each, index) => {
                    return (
                      <div
                        className="col-md-2 col-div"
                        tyle={{ cursor: "pointer" }}
                        style={{ cursor: "pointer" }}
                        // onClick={()=>this.testing(this.state.Compdata.Incorporation)}
                        onClick={() => this.testing(each, each?.doc_name)}
                      >
                        <center>
                          <FaFilePdf
                            style={{ color: "#832120", fontSize: "50px" }}
                          />
                          {/* <p id="para">Example.pdf</p> */}
                          <div style={{ overflowWrap: "anywhere" }}>
                            <label id="label">
                              {each?.doc_name?.replace(
                                /([a-z])([A-Z])/g,
                                `$1 $2`
                              )}
                            </label>
                          </div>
                        </center>
                      </div>
                    );
                  })}
              </div>
            )}
            <br />
            {/* <div className='row pagination'>
                            <div className="pagin">
                                <a href><FaAngleDoubleLeft style={{color:'#31327c'}}/></a> &nbsp;<a href style={{color:'#31327c'}}>1</a> &nbsp; <a href style={{color:'#31327c'}}><FaAngleDoubleRight/></a>
                            </div>
                        </div> */}
          </div>

          <div className="third-row-mobile">
            <div className="top-head" style={{}}>
              <p>
                Documents Uploaded
                {/* <span>Complete the Onboarding Process .</span> */}
              </p>
              {/* <a ><FaList /> <span>{this.state.view?'Block view':'List view'}</span><br/> 
                            </a> */}
            </div>
            <div
              style={{
                height: "270px",
                overflowY: "scroll",
                cursor: "pointer",
              }}
            >
              {Array.isArray(this.state.Compdata) &&
                this.state.Compdata.map((each, index) => {
                  return (
                    <div
                      className="d-flex justify-content-between eachCard"
                      onClick={() => this.testing(each, each?.doc_name)}
                    >
                      <div className="d-flex flex-column justify-content-around">
                        <div className="fw-bold px-2">
                          Blockchain Id :{" "}
                          <span
                            className="fw-normal"
                            style={{
                              color: "#0f878c",
                              overflowWrap: "anywhere",
                            }}
                          >
                            {each?.hash?.slice(0, 5) +
                              "..." +
                              each?.hash?.slice(-5)}
                          </span>
                        </div>
                        <div className="fw-bold px-2">
                          Document Name :{" "}
                          <span
                            className="fw-normal"
                            style={{ overflowWrap: "break-word" }}
                          >
                            {each?.doc_name?.replace(
                              /([a-z])([A-Z])/g,
                              `$1 $2`
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex flex-column justify-content-around p-2">
                        <div>
                          <div className="fw-bold px-2">
                            Timestamp :{" "}
                            <span
                              className="fw-normal"
                              style={{ overflowWrap: "anywhere" }}
                            >
                              {each?.createdAt}
                            </span>
                          </div>
                          <div className="fw-bold px-2">
                            Uploaded On :{" "}
                            <span
                              className="fw-normal"
                              style={{ color: "#1E93BE" }}
                            >
                              {each?.createdAt?.slice(0, 10)}
                            </span>
                          </div>
                        </div>
                        <div
                          className="fw-bold text-end p-2 "
                          style={{
                            color: each.isVerified
                              ? "#008000"
                              : each.isMismatched
                              ? "#FF0000"
                              : "#FFFF00",
                          }}
                        >
                          <BsLink45Deg
                            style={{
                              color: each.isVerified
                                ? "#008000"
                                : each.isMismatched
                                ? "#FF0000"
                                : "#FFFF00",
                            }}
                          />
                          {each.isVerified
                            ? "Verified"
                            : each.isMismatched
                            ? "Mismatched"
                            : each.isRejected
                            ? "Rejected"
                            : "Not verified"}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  console.log(state);
  return {
    companyDetail: state.admin.personal_document,
    companyDetail_err: state.admin.personal_document_err,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      // getCompanyDocuments,\
      personalDocument,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IndividualDetailScreen);
