import React from 'react'
import '../style/_dashboard.scss'
import Ellipse from '../Assets/Icon metro-user.svg'
import menu1 from '../Assets/Group 187.png'
import menu2 from '../Assets/Group 185.png'
import menu3 from '../Assets/Group 186.png'
import menu4 from '../Assets/Group 182.png';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom"

class SideBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            password: '',
            email: '',
            errors: '',
            signIn: true

        };
    }




    handleRedirect = () => {
        this.props.history.push("/SignUp");
    }
    handleChange = (e) => {
        const data = e.target.name
        this.setState({
            [data]: e.target.value
        })
    }

    handleSignIn = () => {
        this.props.history.push("/Dashboard");
    }

    handleRedirectMenu = (data) => {
        this.props.history.push("/" + data);
    }

    render() {
        console.log(this.props)
        const data = window.location.href.split('/')[3]
        return (
            <div className="sideBar">
                <div className="row img-area">
                    <img alt='' src={Ellipse} />
                    <p><div>{localStorage.getItem('username')}</div> <span>Blockchain Registry</span></p>
                </div>
                <div className="menu_area" style={{ fontSize: "1.3rem" }}>
                    <div className="row" style={{ marginTop: '15', cursor: 'pointer' }} onClick={() => this.handleRedirectMenu('Dashboard')}>
                        {/* <img alt='' src={menu1}/> */}
                        <p style={{ color: data === 'Dashboard' ? 'white' : '#aaaaaa', margin: '0%'}}>Dashboard</p>
                    </div>
                    <div className="row" style={{ cursor: 'pointer' }} onClick={() => this.handleRedirectMenu('Company_list')}>
                        {/* <img alt='' src={menu2}/> */}
                        <p style={{ color: data === 'Company_list' ? 'white' : '#aaaaaa',margin:'0%'}}>Companies Registered</p>
                    </div>
                    <div className="row" style={{ cursor: 'pointer' }} onClick={() => this.handleRedirectMenu('Individual_list')}>
                        {/* <img alt='' src={menu2}/> */}
                        <p style={{ color: data === 'Individual_list' ? 'white' : '#aaaaaa',margin:'0%'}}>Individuals Registered</p>
                    </div>
                   
                    <div className="row" style={{ cursor: 'pointer' }}>
                        {/* <img alt='' src={menu3}/> */}
                        <Link to={{ pathname: "http://3.28.231.133:8080/" }} target="_blank" style={{ textDecoration: 'none' }}><p style={{ color: data === 'Dashbytoard' ? 'white' : '#aaaaaa', margin: '0%'}}>Blockchain Explorer</p></Link>
                    </div>
                    <div className="row" style={{ cursor: 'pointer' }} onClick={() => this.handleRedirectMenu('Onboard')}>
                        {/* <img alt='' src={menu4}/> */}
                        <p style={{ color: data === 'Dashboward' ? 'white' : '#aaaaaa', margin: '0%'}}>Onboard new Company</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default SideBar

