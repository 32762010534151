import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';
import './App.css';
import SignUpScreen from './Home/SignUp';
import HomeScreen from './Home/index';
import ForgetPassword from './Home/ForgetPassword.js';
import ResetPassword from './Home/ResetPassword.js'
import GeneralLayout from './Layout/GeneralLayout/index';
import Header1 from './Layout/Header/Header1';
import Header from './Layout/Header/index';
import RouteWithLayout from './Layout/RouteWithLayout';
import CertificateDetailScreen from './Screen/CertificateDetail';
import CompanyDetailScreen from './Screen/CompanyDetail';
import CompanyListScreen from './Screen/CompanyList';
import IndividualListScreen from './Screen/IndividualList.js'
import IndividualDetailScreen from './Screen/IndividualDetail.js'
import SmartContractListScreen from './Screen/SmartContractList.js'
import CompanyOnboardScreen from './Screen/CompanyOnboard';
import DashboardScreen from './Screen/Dashboard';
import OnboardScreen from './Screen/Onboard';
import OnboardProcessScreen from './Screen/OnboardProcess';
import ShowPdf from './Screen/ShowPdf';



import './style/_CompanyOnboard.scss';
import './style/_comapnyList.scss';
import './style/_dashboard.scss';
import './style/_generalLayout.scss';
import './style/_login.scss';
import { ToastContainer } from 'react-toastify';
import Dashboard from './Screen/Dashboard';

function App() {

  return (
    <Router>
      <ToastContainer />
      <Switch>
        <RouteWithLayout layout={GeneralLayout} path="/" exact component={HomeScreen} />
        <RouteWithLayout layout={GeneralLayout} path="/SignUp" component={SignUpScreen} />
        <RouteWithLayout layout={GeneralLayout} path="/forget_password" component={ForgetPassword} />
        <RouteWithLayout layout={GeneralLayout} path="/reset" component={ResetPassword} />

        <RouteWithLayout layout={Header} path="/Dashboard" component={DashboardScreen} />
        <RouteWithLayout layout={Header} path="/Company_list" component={CompanyListScreen} />
        <RouteWithLayout layout={Header} path="/Company_Detail" component={CompanyDetailScreen} />
        <RouteWithLayout layout={Header} path="/Individual_list" component={IndividualListScreen} />
        <RouteWithLayout layout={Header} path="/Individual_Detail" component={IndividualDetailScreen} />
        {/* <RouteWithLayout layout={Header} path="/Smart_Contract_list" component={SmartContractListScreen} /> */}
        <RouteWithLayout layout={Header} path="/Certificate_Detail" component={CertificateDetailScreen} />
        <RouteWithLayout layout={Header} path="/Onboard" component={OnboardScreen} />
        <RouteWithLayout layout={Header} path="/Company_Onboard" component={CompanyOnboardScreen} />
        <RouteWithLayout layout={Header} path="/Onboard_process" component={OnboardProcessScreen} />
        


        {/* <RouteWithLayout layout={Header} path="/Face_Match" component={FaceMatchScreen} /> */}
        <RouteWithLayout layout={Header1} path="/document/:company_id/:hash_value" component={ShowPdf} />
      </Switch>
    </Router>
  );
}

export default App;
