import React from 'react';
import { FiSearch } from "react-icons/fi";
import SideBar from '../Component/SideBar'
import { BsLink45Deg } from "react-icons/bs";
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getCompanyDocuments, personalUsers} from '../store/Admin/action'

class IndividualListScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            password: '',
            email: '',
            errors: '',
            personalList:[],
            signIn: true

        };
    }

    componentDidMount(){
        // this.props.getDashboardCompaniesDetail();
        this.props.personalUsers();
    }

    componentDidUpdate(prev){

        if(prev.fail_personal_users!==this.props.fail_personal_users){
            console.log(prev.fail_personal_users);
            console.log(this.props.fail_personal_users)
            if(!localStorage.getItem('refresh_token')){
                this.props.history.push('/');
            }
        }

        if(prev.succ_personal_users !== this.props.succ_personal_users){
            console.log(this.props.succ_personal_users?.data?.data);
            this.setState({personalList:this.props.succ_personal_users?.data?.data});
        }        
    }

    handleCompanyDetail = (data) => {
        // localStorage.setItem('dmccId', data)
        this.props.history.push({
            pathname: "/Individual_Detail", state: {
                data
            }
        });
        console.log(data);
    }

    render() {
        console.log(this.props)
        return (
            <div className='listed-page'>
                <div className='lg-page-banner'>
                    <SideBar {...this.props} />
                </div>
                <div className='lg-page-form py-3'>
                    <div className='row first-row'>
                        <label>Listed Individuals</label>
                        {/* <input type="text" name="search" placeholder='Search for a Listed Company' />
                        <a href><FiSearch style={{ color: '#2f327d', marginTop: '-14px' }} /></a> */}
                    </div>
                    <div className='row'>
                        <p style={{ marginLeft: '1.5%' }}>Know your details in a quick overview,</p>
                    </div>

                    <div className='row third-row' >
                        <p>Individual Details</p>
                        <hr></hr>
                        <div className="row" >
                            <div className="col-md-1 col">
                                <center>
                                    {/* <input type="checkbox" /> &nbsp;  */}
                                    <p>S.No</p>
                                </center>
                            </div>
                            <div className="col-md-2 col">
                                <center>
                                    <p>User Name</p>
                                </center>
                            </div>
                            <div className="col-md-2 col">
                                <center>
                                    <p>ID</p>
                                </center>
                            </div>
                            <div className="col-md-2 col">
                                <center>
                                    <p>Onboarded On</p>
                                </center>
                            </div>
                            <div className="col-md-3 col">
                                <center>
                                    <p>Transaction ID</p>
                                </center>
                            </div>
                            <div className="col-md-2 col">
                                <center>
                                    <p>Verification Status</p>
                                </center>
                            </div>
                        </div>
                        <hr></hr>
                        {console.log(this.state.personalList)}

                        <div style={{height:'400px',overflowY:'scroll'}}> 
                            {Array.isArray(this.state.personalList)&&this.state.personalList.map((each,index)=>(
                            <>
                                <div className="row" style={{ cursor: 'pointer' }} onClick={() => this.handleCompanyDetail(each)}>
                                    <div className="col-md-1 col">
                                        <center>
                                            {/* <input type="checkbox" /> &nbsp;  */}
                                            <p>{index+1}</p>
                                        </center>
                                    </div>
                                    <div className="col-md-2 col">
                                        <center>
                                            <p>{each?.username}</p>
                                        </center>
                                    </div>
                                    <div className="col-md-2 col">
                                        <center>
                                            <p style={{ color: '#2f327d' }}>{each?._id}</p>
                                        </center>
                                    </div>
                                    <div className="col-md-2 col">
                                        <center>
                                            <p>{each?.createdAt}</p>
                                        </center>
                                    </div>
                                    <div className="col-md-3 col">
                                        <center className='d-flex flex-column'>
                                            <p style={{ color: '#2f327d', overflowWrap:"anywhere" }}>Personal: {each?.personal_Doc_txnID}</p>
                                            <p style={{ color: '#2f327d', overflowWrap:"anywhere" }}>Smart Contract: {each?.smartContract_Doc_txnID}</p>
                                        </center>
                                    </div>
                                    <div className="col-md-2 col">
                                        <center className='d-flex flex-column'>
                                           <div>Personal Documents:
                                           {each?.personal_Doc_txnID==='Not Applicable' ? <p>Not Applicable</p>:
                                                <p><BsLink45Deg style={{color:each.personal_Doc_verified?"#438d27":each.isMismatched?"#cc7a00":"#990000"}} />{each.personal_Doc_verified?"Verified":each.isMismatched?"Mismatched":"Not verified"}</p>
                             } 
                                                </div>
                                           <div>Smart Contract:
                                           {each?.smartContract_Doc_txnID==='Not Applicable' ? <p>Not Applicable</p>:
                                            <p><BsLink45Deg style={{color:each.smartContract_Doc_verified?"#438d27":each.isMismatched?"#cc7a00":"#990000"}} />{each.smartContract_Doc_verified?"Verified":each.isMismatched?"Mismatched":"Not verified"}</p>
                                            } 

                                           </div>
                                        </center>
                                    </div>
                                </div>
                                <hr></hr>
                            </>
                            ))} 
                        </div>
                    </div>

                    <div className='third-row-mobile'>
                    <div className="top-head" style={{}}>
                        <p>Individual Details</p>
                        <hr></hr>
                        </div>
                        <div style={{height:"270px", overflowY:"scroll", cursor:"pointer"}}>
                            {Array.isArray(this.state.personalList) && this.state.personalList.map((each,index)=>{
                                return(
                                    <div className='d-flex justify-content-between eachCard'  onClick={() => this.handleCompanyDetail(each)}>
                                        <div className='d-flex flex-column justify-content-around'>
                                            <div className='fw-bold px-2'>Personal Transaction Id : <span className='fw-normal' style={{color:'#0f878c', overflowWrap:"anywhere"}}>{each?.personal_Doc_txnID==='Not Applicable'?each?.personal_Doc_txnID:each?.personal_Doc_txnID?.slice(0, 5) + "..." + each?.personal_Doc_txnID?.slice(-5)}</span></div>
                                            <div className='fw-bold px-2'>Smart Contract Transaction Id:<span className='fw-normal' style={{color:'#0f878c', overflowWrap:"anywhere"}}>{each?.smartContract_Doc_txnID==='Not Applicable'?each?.smartContract_Doc_txnID:each?.smartContract_Doc_txnID?.slice(0, 5) + "..." + each?.smartContract_Doc_txnID?.slice(-5)}</span></div>
                                           <div className='fw-bold px-2'>User Name : <span className='fw-normal' style={{ overflowWrap:"break-word"}}>{each?.username?.replace(/([a-z])([A-Z])/g, `$1 $2`)}</span></div>
                                        </div>
                                        <div className='d-flex flex-column justify-content-around p-2'>
                                            <div>
                                                <div className='fw-bold px-2'>Timestamp: <span className='fw-normal' style={{ overflowWrap:"anywhere"}}>{each?.createdAt}</span></div>
                                                <div className='fw-bold px-2'>Uploaded On : <span className='fw-normal' style={{color:'#1E93BE'}}>{each?.createdAt?.slice(0,10)}</span></div>
                                            </div>
                                            <div className='fw-bold text-end p-2 ' style={{ color: each.personal_Doc_verified ? '#008000' : each.isMismatched ? '#FF0000' : '#EAAA00' }}>
                                                <span className='fw-bold px-2' style={{color:'black'}}>Personal Document:</span>
                                                {each?.personal_Doc_txnID==='Not Applicable' ? <span className='fw-normal' style={{color:'black'}}>Not Applicable</span>:<span>
                                                    <BsLink45Deg style={{ color: each.personal_Doc_verified ? '#008000' : each.isMismatched ? '#FF0000' : '#EAAA00' }}/>
                                                    {each.personal_Doc_verified?"Verified":each.isMismatched?"Mismatched":"Not verified"}
                                                </span>}
                                            </div>
                                            <div className='fw-bold text-end p-2 ' style={{ color: each.smartContract_Doc_verified ? '#008000' : each.isMismatched ? '#FF0000' : '#EAAA00' }}>
                                                <span style={{color:'black'}}>Smart Contract: </span>
                                                {each?.smartContract_Doc_verified==='Not Applicable' ? <span className='fw-normal' style={{color:'black'}}>Not Applicable</span>:<span>
                                                    <BsLink45Deg style={{ color: each.smartContract_Doc_verified ? '#008000' : each.isMismatched ? '#FF0000' : '#EAAA00' }}/>
                                                    {each.smartContract_Doc_verified?"Verified":each.isMismatched?"Mismatched":"Not verified"}
                                                </span>}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
       
        succ_personal_users:state.admin.personal_user,
        fail_personal_users:state.admin.personal_user_err
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
      {
        getCompanyDocuments,
        personalUsers
      },
      dispatch,
    )
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(IndividualListScreen)