import React from "react";
import { BsLink45Deg } from "react-icons/bs";
import { FiSearch } from "react-icons/fi";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import chain from "../Assets/Group 316.svg";
import SideBar from "../Component/SideBar";
import {
  getCompanyDocuments,
  getDashboardCompaniesDetail,
  getDashboardDetail,
} from "../store/Admin/action";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      totalCompanies: 0,
      totalmismatchedCompanies: 0,
      totalunverifiedCompanies: 0,
      totalverifiedCompanies: 0,
      companyList: [],
      errors: "",
      signIn: true,
    };
  }

  componentDidMount() {
    this.props.getDashboardDetail();
    this.props.getDashboardCompaniesDetail();
  }

  componentDidUpdate(prev) {
    if (
      prev.fail_dashboard !== this.props.fail_dashboard ||
      prev.fail_dashboard_companies !== this.props.fail_dashboard_companies
    ) {
      if (!localStorage.getItem("refresh_token")) {
        this.props.history.push("/");
      }
    }

    if (prev.succ_dashboard !== this.props.succ_dashboard) {
      this.setState({
        totalCompanies:
          this.props.succ_dashboard?.data?.data[0]?.totalCompanies ?? 0,
        totalmismatchedCompanies:
          this.props.succ_dashboard?.data?.data[0]?.totalmismatchedCompanies ??
          0,
        totalunverifiedCompanies:
          this.props.succ_dashboard?.data?.data[0]?.totalunverifiedCompanies ??
          0,
        totalverifiedCompanies:
          this.props.succ_dashboard?.data?.data[0]?.totalverifiedCompanies ?? 0,
      });
    }

    if (prev.succ_dashboard_companies !== this.props.succ_dashboard_companies) {
      console.log(this.props.succ_dashboard_companies);
      this.setState({
        companyList: this.props.succ_dashboard_companies?.data?.data,
      });
    }
  }

  handleCompanyDocuments = (data) => {
    this.props.history.push({
      pathname: "/Company_Detail",
      state: {
        data,
      },
    });
  };

  render() {
    console.log(this.props);
    return (
      <div className="dashboard-page">
        <div className="lg-page-banner">
          <SideBar {...this.props} />
        </div>
        <div className="lg-page-form py-3">
          <div className=" first-row">
            <label>Dashboard</label>
            {/* <input type="text" name="search" placeholder='Search for a Listed Company'/>
                        <a href><FiSearch style={{color:'#0f878c',marginTop:'-14px'}}/></a> */}
          </div>
          <div className="">
            <p style={{ marginLeft: "1.5%" }}>
              Know your details in a quick overview,
            </p>
          </div>
          <div className="second-row">
            {/* <div className="" style={{width:'100%',display:'flex'}}> */}

            <div className="d-flex pairCard">
              <div className="eachCard">
                <div className="row box-col ">
                  <div className="col-md-8">
                    <p>
                      Total Listed Company <br />{" "}
                      <span>Total Listed company in DT</span>
                    </p>
                  </div>
                  <div className="col-md-4">
                    <center>
                      <p style={{ color: "#0f878c" }}>
                        {this.state.totalCompanies}
                      </p>
                    </center>
                  </div>
                </div>
              </div>

              {/* <img alt='' src={chain} style={{width:'6%'}}/> */}

              <div className="eachCard">
                <div className="row box-col">
                  <div className="col-md-8">
                    <p>
                      Verification Done <br />{" "}
                      <span>Blockchain based verification Has been done.</span>
                    </p>
                  </div>
                  <div className="col-md-4">
                    <center>
                      <p style={{ color: "#98CC34" }}>
                        {this.state.totalverifiedCompanies}
                      </p>
                    </center>
                  </div>
                </div>
              </div>
            </div>

            {/* <img className='midChain' alt='' src={chain} style={{width:'3%'}}/> */}

            <div className="d-flex pairCard">
              <div className="eachCard">
                <div className="row box-col">
                  <div className="col-md-8">
                    <p>
                      {" "}
                      Not verified <br />{" "}
                      <span>
                        Blockchain based verification Has not been done.
                      </span>
                    </p>
                  </div>
                  <div className="col-md-4">
                    <center>
                      <p style={{ color: "orange" }}>
                        {this.state.totalunverifiedCompanies}
                      </p>
                    </center>
                  </div>
                </div>
              </div>

              {/* <img alt='' src={chain} style={{width:'6%'}}/> */}

              <div className="eachCard">
                <div className="row box-col">
                  <div className="col-md-8">
                    <p>
                      Mismatched <br />{" "}
                      <span>
                        Verification done and the documents Are not matching has
                        mismatch
                      </span>
                    </p>
                  </div>
                  <div className="col-md-4">
                    <center>
                      <p style={{ color: "red" }}>
                        {this.state.totalmismatchedCompanies}
                      </p>
                    </center>
                  </div>
                </div>
              </div>
            </div>
            {/* </div> */}
          </div>
          <div className="row third-row">
            <p>
              Recently added Companies <br />
              <span>
                Recently added companies with all the required documents.
              </span>
            </p>
            <hr></hr>
            <div className="row">
              <div className="col-md-1 col">
                <center>
                  {/* <input type="checkbox"/> &nbsp;  */}
                  <p>S.No</p>
                </center>
              </div>
              <div className="col-md-2 col">
                <center>
                  <p>Company Name</p>
                </center>
              </div>
              <div className="col-md-2 col">
                <center>
                  <p>Company ID</p>
                </center>
              </div>
              <div className="col-md-2 col">
                <center>
                  <p>Onboarded On</p>
                </center>
              </div>
              <div className="col-md-3 col">
                <center>
                  <p>Transaction ID</p>
                </center>
              </div>
              <div className="col-md-2 col">
                <center>
                  <p>Verification Status</p>
                </center>
              </div>
            </div>
            <hr></hr>
            {/* {this.state.companyList} */}
            {Array.isArray(this.state.companyList) &&
              this.state.companyList.slice(0, 4).map((each, index) => (
                <>
                  <div className="row" style={{ cursor: "pointer" }}>
                    <div className="col-md-1 col">
                      <center>
                        {/* <input type="checkbox"/> &nbsp; */}
                        <p>{index + 1}</p>
                      </center>
                    </div>
                    <div className="col-md-2 col">
                      <center>
                        <p>{each?.company_name}</p>
                      </center>
                    </div>
                    <div
                      className="col-md-2 col"
                      onClick={() => this.handleCompanyDocuments(each)}
                    >
                      <center>
                        <p style={{ color: "#2f327d" }}>{each?.registry_id}</p>
                      </center>
                    </div>
                    <div className="col-md-2 col">
                      <center>
                        <p>{each?.company_registration_date}</p>
                      </center>
                    </div>
                    <div className="col-md-3 col">
                      <center>
                        <p
                          style={{ color: "#2f327d", overflowWrap: "anywhere" }}
                        >
                          {each?.txID}
                        </p>
                      </center>
                    </div>
                    <div className="col-md-2 col">
                      <center>
                        <p>
                          <BsLink45Deg
                            style={{
                              color: each.isVerified
                                ? "#438d27"
                                : each.isMismatched
                                ? "#cc7a00"
                                : "#990000",
                            }}
                          />
                          {each.isVerified
                            ? "Verified"
                            : each.isMismatched
                            ? "Mismatched"
                            : "Not verified"}
                        </p>
                      </center>
                    </div>
                  </div>
                  <hr></hr>
                </>
              ))}
          </div>
          <div className="third-row-mobile">
            <div className="top-head" style={{}}>
              <p>
                Recently added Companies <br />
                <span>
                  Recently added companies with all the required documents.
                </span>
              </p>
              <hr></hr>
            </div>
            <div
              style={{
                height: "270px",
                overflowY: "scroll",
                cursor: "pointer",
              }}
            >
              {Array.isArray(this.state.companyList) &&
                this.state.companyList.slice(0, 4).map((each, index) => {
                  return (
                    <div
                      className="d-flex justify-content-between eachCard"
                      onClick={() => this.handleCompanyDocuments(each)}
                    >
                      <div className="d-flex flex-column justify-content-around">
                        <div className="fw-bold px-2">
                          Transaction Id :{" "}
                          <span
                            className="fw-normal"
                            style={{
                              color: "#0f878c",
                              overflowWrap: "anywhere",
                            }}
                          >
                            {each?.txID?.slice(0, 5) +
                              "..." +
                              each?.txID?.slice(-5)}
                          </span>
                        </div>
                        <div className="fw-bold px-2">
                          Company Name :{" "}
                          <span
                            className="fw-normal"
                            style={{ overflowWrap: "break-word" }}
                          >
                            {each?.company_name?.replace(
                              /([a-z])([A-Z])/g,
                              `$1 $2`
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex flex-column justify-content-around p-2">
                        <div>
                          <div className="fw-bold px-2">
                            Timestamp :{" "}
                            <span
                              className="fw-normal"
                              style={{ overflowWrap: "anywhere" }}
                            >
                              {each?.createdAt}
                            </span>
                          </div>
                          <div className="fw-bold px-2">
                            Uploaded On :{" "}
                            <span
                              className="fw-normal"
                              style={{ color: "#1E93BE" }}
                            >
                              {each?.createdAt?.slice(0, 10)}
                            </span>
                          </div>
                        </div>
                        <div
                          className="fw-bold text-end p-2 "
                          style={{
                            color: each.isVerified
                              ? "#008000"
                              : each.isMismatched
                              ? "#FF0000"
                              : "#EAAA00",
                          }}
                        >
                          <BsLink45Deg
                            style={{
                              color: each.isVerified
                                ? "#008000"
                                : each.isMismatched
                                ? "#FF0000"
                                : "#EAAA00",
                            }}
                          />
                          {each.isVerified
                            ? "Verified"
                            : each.isMismatched
                            ? "Mismatched"
                            : "Not verified"}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    succ_dashboard: state.admin.dashboard,
    fail_dashboard: state.admin.dashboard_err,
    succ_dashboard_companies: state.admin.dashboard_companies,
    fail_dashboard_companies: state.admin.dashboard_companies_err,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getDashboardDetail,
      getDashboardCompaniesDetail,
      getCompanyDocuments,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
