import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import logo from "../Assets/Group 32.png";
import chain from "../Assets/Group 341.png";
import PdfRender from "../Component/PdfRender";
import SideBar from "../Component/SideBar";
import {
  getCertificate,
  rejectCompanyDocument,
  rejectIndividualDocument,
  verifyDocument,
  verifyIndividualDocument,
} from "../store/Admin/action";
import { toast } from "react-toastify";
import load from "../Assets/load";

class CompanyList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      password: "",
      email: "",
      errors: "",
      signIn: true,
      view: false,
      certificate: undefined,
      isVerified: false,
      isMismatched: false,
      isRejected: false,
      sendingRejection: false,
      sendingVerification: false,
    };
  }
  componentDidMount() {
    console.log(this.props?.history?.location?.state);
    this.setState({
      isVerified: this.props?.history?.location?.state.isVerified,
      isMismatched: this.props?.history?.location?.state.isMismatched,
      isRejected: this.props?.history?.location?.state.isRejected,
    });
  }

  componentDidUpdate(prev) {
    if (prev.certificateDetail !== this.props.certificateDetail) {
      console.log(this.props.certificateDetail);
      this.setState({
        certificate: this.props?.certificateDetail?.data?.data?.file,
      });
    }

    if (prev.verify_doc_succ !== this.props.verify_doc_succ) {
      toast.success(this.props.verify_doc_succ?.data?.message);
      this.setState({
        isVerified: true,
        sendingVerification: false,
      });
      //    this.props.history.push('/Company_Detail');
    }

    if (prev.verify_doc_fail !== this.props.verify_doc_fail) {
      console.log(this.props.verify_doc_fail);
      toast.error(
        this.props.verify_doc_fail?.response?.data?.message ||
          this.props.verify_doc_fail?.response?.data?.Error ||
          "Something went wrong"
      );
      this.setState({
        sendingVerification: false,
      });
    }
    if (prev.verify_personal_succ !== this.props.verify_personal_succ) {
      toast.success(this.props.verify_personal_succ?.data?.message);
      this.setState({
        isVerified: true,
        sendingVerification: false,
      });
      //    this.props.history.push('/Company_Detail');
    }

    if (prev.verify_personal_fail !== this.props.verify_personal_fail) {
      console.log(this.props.verify_personal_fail);
      toast.error(
        this.props.verify_personal_fail?.response?.data?.message ||
          this.props.verify_personal_fail?.response?.data?.Error ||
          "Something went wrong"
      );
      this.setState({
        sendingVerification: false,
      });
    }
    if (prev.reject_document_succ !== this.props.reject_document_succ) {
      toast.success(this.props.reject_document_succ?.data?.message);
      this.setState({
        isRejected: true,
        sendingRejection: false,
      });
      // this.props.history.push("/Company_Detail");
    }

    if (prev.reject_document_fail !== this.props.reject_document_fail) {
      console.log(this.props.reject_document_fail);
      toast.error(
        this.props.reject_document_fail?.response?.data?.message ||
          this.props.reject_document_fail?.response?.data?.Error ||
          "Something went wrong"
      );
      this.setState({
        sendingRejection: false,
      });
    }

    if (
      prev.reject_company_document_succ !==
      this.props.reject_company_document_succ
    ) {
      toast.success(this.props.reject_company_document_succ?.data?.message);
      this.setState({
        isRejected: true,
        sendingRejection: false,
      });
      // this.props.history.push("/Company_Detail");
    }

    if (
      prev.reject_company_document_fail !==
      this.props.reject_company_document_fail
    ) {
      console.log(this.props.reject_company_document_fail);
      toast.error(
        this.props.reject_company_document_fail?.response?.data?.message ||
          this.props.reject_company_document_fail?.response?.data?.Error ||
          "Something went wrong"
      );
      this.setState({
        sendingRejection: false,
      });
    }
  }

  handleChange = (e) => {
    const data = e.target.name;
    this.setState({
      [data]: e.target.value,
    });
  };

  handleSendForVerification = () => {
    console.log("handleverification", this.props.history.location.state);
    let text = "Once verified, it can't be undone. Do you want to continue?";
    if (window.confirm(text) == true) {
      this.setState({
        sendingVerification: true,
      });
      if (this.props?.history?.location?.state?.docType === "Personal") {
        this.props.verifyIndividualDocument({
          UID: this.props?.history?.location?.state?.data?.UID,
          body: {
            doc_name: this.props?.history?.location?.state?.certificateName,
          },
        });
      } else {
        this.props.verifyDocument({
          companyId: this.props?.history?.location?.state?.data?.company_id,
          body: {
            doc_name: this.props?.history?.location?.state?.certificateName,
          },
        });
      }
    } else {
      return;
    }
  };

  handleSendBack = () => {
    const reason = window.prompt("Enter reason for sending back");
    if (reason) {
      if (this.props?.history?.location?.state?.docType === "Personal") {
        this.props.rejectIndividualDocument({
          UID: this.props?.history?.location?.state?.data?.UID,
          body: {
            doc_name: this.props?.history?.location?.state?.certificateName,
            rejection_remark: reason,
          },
        });
      } else {
        this.props.rejectCompanyDocument({
          companyId: this.props?.history?.location?.state?.data?.company_id,
          body: {
            doc_name: this.props?.history?.location?.state?.certificateName,
            rejection_remark: reason,
          },
        });
      }
    } else {
      toast.error("Please provide the reason");
      return;
    }
  };

  render() {
    console.log(this.props);
    return (
      <div className="listed-page">
        <div className="lg-page-banner">
          <SideBar {...this.props} />
        </div>
        <div className="lg-page-form py-3">
          <div className="pageData">
            <div className="first-row">
              <img src={logo} />
              <p>
                {this.props.history.location.state !== undefined
                  ? this.props.history.location.state.certificateName
                  : ""}{" "}
                <br />{" "}
                <span>
                  Verifying certificate of Incorporation by using Blockchain
                  based verification system
                </span>
              </p>
            </div>
            <div className="row second-row">
              <div className="col-lg-6 position-relative">
                <PdfRender
                  certificate={this.props.history.location.state?.data?.doc_id}
                />
              </div>
              <div className="col-lg-6 py-4">
                <div className="col-first">
                  {/* <img src ={logod} /> */}
                  <p>
                    {this.props.history?.location?.state?.data?.company_name}{" "}
                    <br />
                    <span id="span">
                      Know your details in a quick overview,
                    </span>{" "}
                    <br />
                    {/* <span id="span1">
                      Registry Id{" "}
                      {
                        this.props.history?.location?.state?.data
                          ?.company_registration_date
                      }
                    </span>{" "}
                    &nbsp; &nbsp; */}
                    <span id="span2">
                      {this.props.history?.location?.state?.data?.registry_id}
                    </span>
                    <br />
                    <span id="span1">
                      Trandsaction Id:{" "}
                      {
                        this.props.history?.location?.state?.data
                          ?.company_registration_date
                      }
                    </span>{" "}
                    <span id="span3">
                      {this.props.history?.location?.state?.data?.txID}
                    </span>
                  </p>
                </div>
                <div className="col-second">
                  <img src={chain} style={{ marginTop: "-1%" }} />
                  <p>
                    {this.state.isVerified
                      ? "Verified by BlockChain"
                      : this.state.isMismatched
                      ? "Mismatched by BlockChain"
                      : "Not verified by BlockChain"}
                  </p>
                </div>
                <div className="row col-third">
                  <div className="col-md-3">
                    <p>Status</p>
                  </div>
                  <div className="col-md-9">
                    {/* <p>: Document Verified successfully</p> */}
                    <p>
                      {this.state.isVerified
                        ? "Document Verified successfully"
                        : this.state.isMismatched
                        ? "Document Mismatched"
                        : "Document not verified"}
                    </p>
                  </div>
                </div>
                <div className="row col-third1">
                  <div className="col-md-3">
                    <p>Time Stamp</p>
                  </div>
                  <div className="col-md-9">
                    <p>
                      {this.props?.history?.location?.state?.data?.createdAt}
                    </p>
                  </div>
                </div>
                {/* <div className="row col-third1">
                  <div className="col-md-3">
                    <p>Issued by</p>
                  </div>
                  <div className="col-md-9">
                    <p>: Dubai Tourism</p>
                  </div>
                </div> */}
                <div className="col-fourth ">
                  {/* {this.state.verified ? <button disabled> Verification</button> : <button onClick={this.handleSendForVerification}>Send For Verification</button>} */}

                  {!this.state.isVerified && !this.state.isRejected && (
                    <button
                      onClick={this.handleSendBack}
                      style={{
                        cursor: "pointer",
                        border: "1px solid black",
                        marginBottom: "5px",
                      }}
                    >
                      {this.state.sendingRejection ? "Sending..." : "Send Back"}
                    </button>
                  )}
                  {this.state.isRejected && (
                    <button
                      style={{
                        cursor: !this.state.isRejected ? "pointer" : "none",
                        border: "1px solid black",
                        marginBottom: "5px",
                      }}
                      disabled={this.state.isRejected}
                    >
                      Sent Back
                    </button>
                  )}
                  {this.state.isVerified ? (
                    <button disabled style={{ cursor: "not-allowed" }}>
                      Verification
                    </button>
                  ) : this.state.isMismatched ? (
                    <button>Mismatch</button>
                  ) : (
                    !this.state.isRejected && (
                      <button
                        onClick={this.handleSendForVerification}
                        disabled={this.state.sendingVerification}
                      >
                        {this.state.sendingVerification
                          ? "Sending..."
                          : "Send For Verification"}
                      </button>
                    )
                  )}
                  {/* <button onClick={this.handleSendForVerification}>Send For Verification</button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  console.log(state);
  return {
    certificateDetail: state.admin.getCertificate,
    certificateDetail_err: state.admin.getCertificate_err,
    verify_doc_succ: state.admin.verify_doc,
    verify_doc_fail: state.admin.verify_doc_err,
    verify_personal_succ: state.admin.verify_indivudual_doc,
    verify_personal_fail: state.admin.verify_indivudual_doc_err,
    reject_document_succ: state.admin.reject_document,
    reject_document_fail: state.admin.reject_document_err,
    reject_company_document_succ: state.admin.reject_company_document,
    reject_company_document_fail: state.admin.reject_company_document_err,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCertificate,
      verifyDocument,
      verifyIndividualDocument,
      rejectCompanyDocument,
      rejectIndividualDocument,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyList);
