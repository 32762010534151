import React, { PureComponent } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import load from "../Assets/loader";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default class PDFView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      numPages: "",
      pageNumber: 1,
      url: "",
    };
  }

  onDocumentLoadSuccess = (numPages) => {
    this.setState({
      numPages: numPages,
    });
  };

  componentDidMount() {
    console.log(this.props);
    this.setState({
      url: `https://node.blockchainregistry.ai/view_document/${this.props?.certificate}`,
    });
  }

  render() {
    console.log(this.props.certificate);
    return (
      <div className="pdf w-100 h-100">
        {this.props.certificate !== undefined ? (
          <embed
            // src={`https://gateway.pinata.cloud/ipfs/${
            //   this.props?.certificate ?? ""
            // }`}
            src={this.state.url}
            height="100%"
            width="100%"
          />
        ) : (
          <img src={load} style={{ marginTop: "25%", marginLeft: "25%" }} />
        )}
      </div>
    );
  }
}
