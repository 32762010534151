import React from "react";
import { FaPlus } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { bindActionCreators } from "redux";
import swal from "sweetalert";
import building from "../Assets/Group 11.svg";
import user from "../Assets/Group 12.svg";
import pdf from "../Assets/Group 14.svg";
import one from "../Assets/Group 3.svg";
import two from "../Assets/Group 4.svg";
import three from "../Assets/Group 5 (1).svg";
import four from "../Assets/Group 6.svg";
import load from "../Assets/load";
import Modal from "../Component/AddNodal";
import OnboardSideBar from "../Component/OnboardSidebar";
import { UploadDocumentApi } from "../store/Admin/action";

class OnboardProcess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      password: "",
      email: "",
      errors: "",
      signIn: true,
      loadState: false,
      addState: false,
    };
  }

  componentDidMount() {}

  componentDidUpdate(prev) {
    if (prev.fail_documentUpload !== this.props.fail_documentUpload) {
      console.log(this.props.fail_documentUpload);
      toast.error(this.props?.fail_documentUpload?.response?.data?.message);
      this.setState({
        loadState: false,
      });
      if (!localStorage.getItem("refresh_token")) {
        toast.error("Session Expired");
        this.props.history.push("/");
      }
    }

    if (prev.succ_documentUpload !== this.props.succ_documentUpload) {
      console.log(this.props.succ_documentUpload);
      swal({ text: "Document Uploaded Successfully " }).then((res) => {
        this.setState({
          loadState: false,
        });
        this.props.history.push("/Dashboard");
      });
    }
  }

  handlePassport = (e) => {
    console.log(e.target.files[0]);
    const name = e.target.name;
    console.log(name);
    this.setState({
      [name]: e.target.files[0],
    });
  };

  cleanFile = (data) => {
    const name = data;
    this.setState({
      [name]: undefined,
    });

    document.getElementById(`upload-${name}`).value = "";
  };

  handleValidation() {
    const errors = {};
    let formIsValid = true;

    //  if (this.state.file8 === undefined || this.state.file8 === '') {
    //     formIsValid = false;
    //     errors.file8 = 'This is a required field';
    //   }
    //   if (this.state.file7 === undefined || this.state.file7 === '') {
    //     formIsValid = false;
    //     errors.file7 = 'This is a required field';
    //   }
    //   if (this.state.file6 === undefined || this.state.file6 === '') {
    //     formIsValid = false;
    //     errors.file6 = 'This is a required field';
    //   }
    if (this.state.file3 === undefined || this.state.file3 === "") {
      formIsValid = false;
      errors.file3 = "This is a required field";
    }

    if (this.state.file1 === undefined || this.state.file1 === "") {
      formIsValid = false;
      errors.file1 = "This is a required field";
    }
    if (this.state.file2 === undefined || this.state.file2 === "") {
      formIsValid = false;
      errors.file2 = "This is a required field";
    }
    if (this.state.file4 === undefined || this.state.file4 === "") {
      formIsValid = false;
      errors.file4 = "This is a required field";
    }
    //   if (this.state.file5 === undefined || this.state.file5 === '') {
    //     formIsValid = false;
    //     errors.file5 = 'This is a required field';
    //   }
    //   if (this.state.file9 === undefined || this.state.file9 === '') {
    //     formIsValid = false;
    //     errors.file9 = 'This is a required field';
    //   }

    this.setState({ errors });
    return formIsValid;
  }

  handleSubmit = () => {
    if (this.handleValidation()) {
      this.setState({
        loadState: true,
      });
      var min = 1;
      var max = 1000;
      var rand = Math.round(min + Math.random() * (max - min));

      console.log(rand);
      localStorage.setItem("random", rand);

      const formData = new FormData();
      formData.append("Incorporation", this.state.file1);
      formData.append("MoaAndAoa", this.state.file2);
      formData.append("Incumberency", this.state.file3);
      formData.append("Licence_CR", this.state.file4);

      this.props.UploadDocumentApi({
        body: formData,
        companyId: this.props.history.location.state.companyId,
      });
    }
  };

  handleAdd = () => {
    this.setState({
      addState: !this.state.addState,
    });
  };

  render() {
    console.log(this.state);
    return (
      <div className="Onboard-page">
        {this.state.addState === true && <Modal handleAdd={this.handleAdd} />}
        <ToastContainer />
        <div className="lg-page-banner">
          <OnboardSideBar {...this.props} />
        </div>
        <div className="lg-page-form py-3">
          <div className="row first-row" style={{ display: "flex" }}>
            <label>Onboarding Process</label>
          </div>
          <div className="row">
            <p style={{ marginLeft: "1.5%" }}>
              Complete the Onboarding Process.
            </p>
          </div>
          <div className="row second-form">
            <div className="coling">
              <img src={one} />
            </div>
            <div className="coling1">
              <p style={{ color: "#2f327d" }}>Company Profile</p>
            </div>
            <div className="coling">
              <hr />
            </div>
            <div className="coling">
              <img src={two} />
            </div>
            <div className="coling1">
              <p style={{ color: "#2f327d" }}>Share Holding</p>
            </div>
            <div className="coling">
              <hr />
            </div>
            <div className="coling">
              <img src={three} />
            </div>
            <div className="coling1">
              <p style={{ color: "#2f327d" }}>Blockchain Documents</p>
            </div>
            {/* <div className='coling'>
                        <hr/>
                        </div>
                        <div className='coling'>
                            <img src={four}/>
                        </div>
                        <div className='coling1'>
                            <p>Verification</p>
                        </div> */}
          </div>
          <div className="row third-row">
            <div className="col-md-6">
              <img src={building} />
              <p>
                Document Submission <br />
                <span>
                  Kindly Submit all your company relevant documents(Kindly scan
                  both sides of document*)
                </span>
              </p>
            </div>
            {/* <div className='col-md-6 add-user'>
                            <img src={user}/><a>Add New User Role</a>
                        </div> */}
          </div>
          <div className="row fourth-row" style={{ display: "flex" }}>
            <p>General Documents</p>
            {/* <button onClick={this.handleAdd} style={{width:'250px',marginLeft:'70%',padding:'5px',backgroundColor:'#21201c',color:'#f4a824',border:'1px solid #21201c',marginTop:'-2%',borderRadius:'3px',marginBottom:'2rem'}}><FaPlus/> &nbsp; Add another document</button> */}
          </div>
          <div className="row sixth-row">
            <div className="col-md-4">
              <p>
                Copy of Certificate of Incorporation{" "}
                <span style={{ color: "red", marginLeft: "10px" }}>*</span>
              </p>
              <div>
                <p>Valid Format PDF.</p>
                <input
                  type="file"
                  id="upload-file1"
                  name="file1"
                  accept=".pdf"
                  hidden
                  onChange={this.handlePassport}
                />
                <label for="upload-file1">Upload</label>
              </div>
              {this.state.errors.file1 !== undefined && (
                <p style={{ color: "red", fontSize: "13px", marginTop: "0px" }}>
                  {this.state.errors.file1}
                </p>
              )}
              {this.state.file1 !== undefined && (
                <div className="paradiv">
                  <img src={pdf} />
                  <a id="para">{this.state.file1.name}</a>{" "}
                  <ImCross
                    style={{
                      fontSize: "10px",
                      marginTop: "3%",
                      marginLeft: "10px",
                    }}
                    onClick={() => this.cleanFile("file1")}
                  />
                </div>
              )}
            </div>
            <div className="col-md-4">
              <p>
                Copy of MOA & AOA{" "}
                <span style={{ color: "red", marginLeft: "10px" }}>*</span>
              </p>
              <div>
                <p>Valid Format PDF</p>
                <input
                  type="file"
                  id="upload-file2"
                  name="file2"
                  accept=".pdf"
                  hidden
                  onChange={this.handlePassport}
                />
                <label for="upload-file2">Upload</label>
              </div>
              {this.state.errors.file2 !== undefined && (
                <p style={{ color: "red", fontSize: "13px", marginTop: "0px" }}>
                  {this.state.errors.file2}
                </p>
              )}
              {this.state.file2 !== undefined && (
                <div className="paradiv">
                  <img src={pdf} />
                  <a id="para">{this.state.file2.name}</a>{" "}
                  <ImCross
                    style={{
                      fontSize: "10px",
                      marginTop: "3%",
                      marginLeft: "10px",
                    }}
                    onClick={() => this.cleanFile("file2")}
                  />
                </div>
              )}
            </div>
            <div className="col-md-4">
              <p>
                Certificate of Incumbency{" "}
                <span style={{ color: "red", marginLeft: "10px" }}>*</span>
              </p>
              <div>
                <p>Valid Format PDF</p>
                <input
                  type="file"
                  id="upload-file3"
                  name="file3"
                  accept=".pdf"
                  hidden
                  onChange={this.handlePassport}
                />
                <label for="upload-file3">Upload</label>
              </div>
              {this.state.errors.file3 !== undefined && (
                <p style={{ color: "red", fontSize: "13px", marginTop: "0px" }}>
                  {this.state.errors.file3}
                </p>
              )}
              {this.state.file3 !== undefined && (
                <div className="paradiv">
                  <img src={pdf} />
                  <a id="para">{this.state.file3.name}</a>{" "}
                  <ImCross
                    style={{
                      fontSize: "10px",
                      marginTop: "3%",
                      marginLeft: "10px",
                    }}
                    onClick={() => this.cleanFile("file3")}
                  />
                </div>
              )}
            </div>
            <div className="col-md-6">
              <p>
                Licence/CR
                <span style={{ color: "red", marginLeft: "10px" }}>*</span>
              </p>
              <div>
                <p>Valid Format PDF</p>
                <input
                  type="file"
                  id="upload-file4"
                  name="file4"
                  accept=".pdf"
                  hidden
                  onChange={this.handlePassport}
                />
                <label for="upload-file4">Upload</label>
              </div>
              {this.state.errors.file4 !== undefined && (
                <p style={{ color: "red", fontSize: "13px", marginTop: "0px" }}>
                  {this.state.errors.file4}
                </p>
              )}
              {this.state.file4 !== undefined && (
                <div className="paradiv">
                  <img src={pdf} />
                  <a id="para">{this.state.file4.name}</a>{" "}
                  <ImCross
                    style={{
                      fontSize: "10px",
                      marginTop: "3%",
                      marginLeft: "10px",
                    }}
                    onClick={() => this.cleanFile("file4")}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="row seventh-row">
            {this.state.loadState === false ? (
              <button onClick={this.handleSubmit}>Submit</button>
            ) : (
              <img src={load} style={{ width: "14%", marginLeft: "80%" }} />
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  console.log(state);
  return {
    succ_documentUpload: state.admin.upload_succ,
    fail_documentUpload: state.admin.upload_err,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      UploadDocumentApi,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardProcess);
