import React, { Component } from 'react';
import { BiSearch , BiBell } from "react-icons/bi";
import {FiMenu, FiSettings} from 'react-icons/fi';
import {BsSearch} from 'react-icons/bs';
import logo from '../../Assets/Blockhain_reg_new.png'
import user from '../../Assets/Icon metro-user.svg'
import { RiArrowDropDownLine } from "react-icons/ri";

class GeneralLayout extends Component {

    constructor(){
        super();
        this.state={
            notification_count:0,
            open:false,
            showSidebar:false,
        }
        this.dropdownRef = React.createRef();
        this.dropdownSide = React.createRef();
    }

    componentDidMount=()=>{
    //    this.ringNotification();
        if(localStorage.getItem('access_token')===null){
            this.props.history.push('/');
        }
        window.addEventListener('click', this.handleClickOutside);
    }

    componentWillUnmount = () => {
        window.removeEventListener('click', this.handleClickOutside);
    };

    handleClickOutside = (event) => {
        if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target)) {
            this.setState({ open: false });
        }
        if (this.dropdownSide.current && !this.dropdownSide.current.contains(event.target)) {
            this.setState({ showSidebar: false });
        }
    };

    
    handledown=()=>{
        this.setState({
            open: !this.state.open
        })
    }
    
    handleLogout=()=>{
        localStorage.clear();
        this.setState({
            open: false
        })
        this.props.history.push('/')
    }

    handleLogo=()=>{
        this.props.history.push('/Dashboard')
    }

    handleShowSideBar=()=>{
        this.setState({
            showSidebar: !this.state.showSidebar
        })
    }

    
    handleRedirectMenu = (data) => {
        this.props.history.push(data);
    }

  
    render(){
        return  <div className='general-layout'>
                    <div className='gn-layout-header'>
                        <div title="Blockchain Registry" className='gn-ly-header-left' onClick={this.handleLogo} style={{cursor:'pointer', paddingTop: "20px", paddingBottom: "20px", }}>
                            <img src={logo} id="big-logo" alt="blockchain registry"/>
                        </div>
                        <div className='gn-ly-header-right'>
                            <div className='input-box'>
                                <a><img src={user} /></a>
                                <p><span>{localStorage.getItem('username')}</span> <br/> <span>Blockchain Registry</span></p>
                            </div>
                            {/* <div className="dropdown show">
                                <div className='bell-box' >
                                </div>
                            </div> */}
                            <div className='settings-box'>
                                <FiSettings id="icon_setting"></FiSettings>
                            </div>
                            <div className='settings-box1' style={{position:'relative'}} ref={this.dropdownRef}>
                                <RiArrowDropDownLine id="icon_drop" onClick={this.handledown}/>
                                {this.state.open == true &&
                                    <ul style={{position:'absolute',background:'#000000',padding:'10px 20px',zIndex:'1', right:'0px',top:'30px', borderRadius:'5px'}}>
                                        <li style={{color:'lightgrey',listStyle:'none',fontWeight:'bold',cursor:'pointer', borderBottom:'0.1rem solid white'}} onClick={this.handleLogout}>Logout</li>
                                    </ul>
                                }
                            </div>
                            <div className='mobile-menu' onClick={this.handleShowSideBar} ref={this.dropdownSide}>
                                <FiMenu></FiMenu>
                                {this.state.showSidebar == true &&
                                   <div className='d-flex flex-column justify-content-between dropDownMenu'>
                                   <div>
                                       <ul>
                                           <li className='fs-3 bold pb-4'>{localStorage.getItem('username')}</li>
                                           <li onClick={() => this.handleRedirectMenu('/Dashboard')}>Dashboard</li>
                                           <li onClick={() => this.handleRedirectMenu('/Company_list')}>Companies Registered</li>
                                           <li onClick={() => this.handleRedirectMenu('/Individual_list')}>Individuals Registered</li>
                                           <li><a href="http://3.28.231.133:8080/" target="_blank">Blockchain Explorer</a></li>
                                           <li style={{borderBottom:'none'}} onClick={() => this.handleRedirectMenu('/Onboard')}>Onboard new Company</li>
                                       </ul>
                                   </div>
                                   <div className='fs-5 bold text-center' onClick={this.handleLogout}>Logout</div>
                               </div>
                                }
                            </div>

                        </div>
                        
                    </div>
                    <div className='gn-middle-header'>
                        
                    </div>
                    <div className='gn-layout-body'>
                        {this.props.children}
                        
                    </div>
                </div>
    }
}

export default GeneralLayout;