import React from "react";
import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { SiHiveBlockchain } from "react-icons/si";
import verified from "../Assets/blockchain.jpg";
import trademark from "../Assets/tradeMark.png";
import logo from "../Assets/Logo-08.png";

import { withRouter } from "react-router-dom";
// import { Viewer } from '@react-pdf-viewer/core';
import { PDFDocument, rgb } from "pdf-lib";
import QRCode from "qrcode";

class ShowPdf extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      password: "",
      email: "",
      errors: "",
      signIn: true,
      view: false,
      hashvalue: "",
      companyId: "",
      txId: "",
      isVerified: false,
      isMismatched: false,
      timeStamp: "",
      modifiedPDF: "",
    };
  }

  componentDidMount() {
    console.log(this.props.match.params);

    if (this.props.location.pathname !== undefined) {
      const url = this.props.location.pathname.split("/");
      console.log(url);
      const UrlData = url[4];

      // if(this.state.hashvalue && this.state.companyId){
      // axios.get(`http://20.174.2.234:8084/get_particular_doc_details/${this.props.match.params.company_id}/${this.props.match.params.hash_value}`)
      axios
        .get(
          `https://node.blockchainregistry.ai/get_particular_doc_details/${this.props.match.params.company_id}/${this.props.match.params.hash_value}`
        )
        .then((res) => {
          console.log(res?.data?.data);
          this.setState({
            hashvalue: res?.data?.data?.hash,
            txId: res?.data?.data?.txID,
            companyId: res?.data?.data?.company_id,
            isVerified: res?.data?.data?.isVerified,
            isMismatched: res?.data?.data?.isMismatched,
            timeStamp: res?.data?.data?.updatedAt,
            doc_id: res?.data?.data?.doc_id,
          });
          this.generatePdf();
        })
        .catch((err) => {});
      // }
    }
  }

  generatePdf() {
    const modifyPDF = async () => {
      // const pdfUrl = `https://gateway.pinata.cloud/ipfs/${this.state.hashvalue}`;
      const pdfUrl = `https://node.blockchainregistry.ai/view_document/${this.state?.doc_id}`;
      const pdfBytes = await fetch(pdfUrl).then((res) => res.arrayBuffer());
      const pdfDoc = await PDFDocument.load(pdfBytes);
      const pages = pdfDoc.getPages();

      this.setState({
        numPages: pdfDoc.getPageCount(),
      });

      const qrCodeDataUrl = await QRCode.toDataURL(window.location.href);
      const qrCodeImage = await pdfDoc.embedPng(qrCodeDataUrl);

      const imageBytes = await fetch(logo).then((res) => res.arrayBuffer());
      const logoImage = await pdfDoc.embedPng(imageBytes);

      const { width, height } = pages[0].getSize();

      pages.forEach((page) => {
        const { width, height } = page.getSize();
        page.drawImage(logoImage, {
          x: width - 150,
          y: -60,
          width: 200,
          height: 200,
        });

        page.drawText(`Verified HashId- ${this.state.hashvalue}`, {
          x: 100,
          y: 60,
          size: 10,
          color: rgb(0, 0.53, 0.71),
        });

        page.drawText(
          `The document is blockchain verified and does not require signature.`,
          {
            x: 120,
            y: 40,
            size: 10,
            color: rgb(1, 0.53, 0.71),
          }
        );
        page.drawText(
          ` Verify the document by scanning the QR code. Any correction will render the document invalid`,
          {
            x: 90,
            y: 25,
            size: 10,
            color: rgb(1, 0.53, 0.71),
          }
        );
        page.drawImage(qrCodeImage, {
          x: 0,
          y: 0,
          width: 80,
          height: 80,
        });
      });

      const modifiedPdfBytes = await pdfDoc.save();
      const modifiedPdfBlob = new Blob([modifiedPdfBytes], {
        type: "application/pdf",
      });
      this.setState({
        modifiedPDF: URL.createObjectURL(modifiedPdfBlob),
      });
    };

    modifyPDF();
  }

  onDocumentLoadSuccess = () => {
    console.log(this.state.numPages);
  };

  render() {
    console.log(this.state);
    const isMobile = window.innerWidth < 768;

    return (
      <Fragment>
        <div
          style={{
            border: "1px solid rgba(0, 0, 0, 0.3)",

            overflowX: "hidden",
          }}
        >
          <div className="row">
            <center>
              <div style={{ padding: "3rem" }}>
                <div>
                  {" "}
                  <img src={verified} style={{ width: "150px" }} />
                  <label
                    style={{
                      marginLeft: "20px",
                      fontSize: "25px",
                      color: "#23898e",
                    }}
                  ></label>
                </div>
                <br />
                <label
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "20px",
                  }}
                >
                  {" "}
                  Blockchain Transaction Identifier &nbsp;: &nbsp;
                  <div
                    style={{
                      fontSize: "16px",
                      color: "",
                      ...(isMobile && {
                        width: "400px",
                        overflowWrap: "anywhere",
                      }),
                    }}
                  >
                    {this.state?.txId}
                  </div>
                </label>
              </div>
            </center>
            <div
              style={{ width: "100%" }}
              className={
                isMobile
                  ? "d-flex flex-column"
                  : "d-flex justify-content-center"
              }
            >
              {/* {this.state.certificate !== undefined &&
                                <embed src={`data:application/pdf;base64,${this.state.certificate?.file}`} height={650}
                                width={600}/>
                            } */}

              {isMobile ? (
                <>
                  <div className="m-auto">
                    <label
                      className=""
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        fontSize: "20px",
                      }}
                    >
                      <div className="m-3">Blockchain Verified On:</div>
                      <div
                        className="py-1 px-4 d-flex flex-column m-3"
                        style={{
                          backgroundColor: "black",
                          color: "lightgrey",
                          fontSize: "18px",
                          borderRadius: "10px",
                        }}
                      >
                        <label>
                          Date :{" "}
                          <span
                            style={{ fontSize: "16px", color: "lightgrey" }}
                          >
                            {this.state?.timeStamp.slice(0, 10)}
                          </span>
                        </label>
                        <label>
                          Time :{" "}
                          <span
                            style={{ fontSize: "16px", color: "lightgrey" }}
                          >
                            {this.state?.timeStamp.slice(11, 24)}
                          </span>
                        </label>
                      </div>
                    </label>
                  </div>
                  <div className="m-auto w-100">
                    <iframe
                      src={this.state.modifiedPDF}
                      style={{ width: "100%", height: "650px", border: "none" }}
                    ></iframe>
                  </div>
                </>
              ) : (
                <>
                  <iframe
                    src={this.state.modifiedPDF}
                    style={{ width: "40%", height: "650px", border: "none" }}
                  ></iframe>
                  <div className="m-3">
                    <label
                      className=""
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        fontSize: "20px",
                      }}
                    >
                      <div className="m-3">Blockchain Verified On:</div>
                      <div
                        className="py-1 px-4 d-flex flex-column m-3"
                        style={{
                          backgroundColor: "black",
                          color: "lightgrey",
                          fontSize: "18px",
                          borderRadius: "10px",
                        }}
                      >
                        <label>
                          Date :{" "}
                          <span
                            style={{ fontSize: "16px", color: "lightgrey" }}
                          >
                            {this.state?.timeStamp.slice(0, 10)}
                          </span>
                        </label>
                        <label>
                          Time :{" "}
                          <span
                            style={{ fontSize: "16px", color: "lightgrey" }}
                          >
                            {this.state?.timeStamp.slice(11, 24)}
                          </span>
                        </label>
                      </div>
                    </label>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="position-absolute  bottom-0">
            <img src={trademark} style={{ width: "100px", height: "100px" }} />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ShowPdf;
