import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Modal } from 'react-responsive-modal';
import { useSelector, useDispatch } from 'react-redux'
import { remarkHistory } from '../store/Admin/action'
import axios from 'axios';
import ModalData from './Modal'

const AddNodal = (props) => {
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch()
  const [data,SetData] = useState([])
  // const listData = useSelector((store) => store.Admin.getlistPurchase)
  
  useEffect(() => {
    // console.log(listData)
    // const data=[]
    // for(var i=0;i<5;i++){
    //   data.push(listData.data.result[i])
    // }
    // console.log(data)
    // SetData(data)
  })
  
  console.log(data)

  return (
    <div>
      <ModalData open={open} onClick={() => props.onCloseModal()} center>
      
        <div style={{display:'flex'}}>          
          <p style={{color:'white',fontSize:'20px',fontWeight:'300',marginTop:'20px',marginLeft:'33%'}}>Blockchain Activity</p> 
            
          <a onClick={props.handleAdd} style={{color:'black',fontWeight:'bold',marginLeft:'35%',marginTop:'1%',fontSize:'20px',cursor:'pointer'}}>X</a>
        </div> 
        <div style={{backgroundColor:'white',height:'91%',padding:'1rem'}}>
          <div className="row" style={{marginTop:'3rem'}}>
            <div className='col-md-5'>
                <label style={{fontWeight:'bold'}}>Enter the document name</label>
            </div>
            <div className='col-md-7' style={{display:'flex'}}>
                <p>:</p> &nbsp; <input type="text"  style={{width:'90%',border:'1px solid lightgray',borderRadius:'3px',height:'30px'}}/>
            </div>
          </div>
          <div className="row" style={{marginTop:'1rem'}}>
            <div className='col-md-5'>
                <label style={{fontWeight:'bold'}}>Upload document</label>
            </div>
            <div className='col-md-7' style={{display:'flex'}}>
                <p>:</p> &nbsp; <input type="file"  style={{width:'90%',border:'1px solid lightgray',borderRadius:'3px',height:'30px'}}/>
            </div>
          </div>
          <div className="row" style={{marginTop:'3rem'}}>
            <div className='col-md-5'>
            </div>
            <div className='col-md-2 offset-md-4' style={{display:'flex'}}>
                <button onClick={props.handleAdd} style={{width:'200px',padding:'5px',backgroundColor:'#21201c',color:'#f4a824',border:'1px solid #21201c',marginTop:'-2%',borderRadius:'3px',marginBottom:'2rem'}}> Submit</button>
            </div>
          </div>
        </div>
        
      </ModalData>
    </div>
  );
};
export default AddNodal