export const REGISTER_API_ERR = "REGISTER_API_ERR";
export const REGISTER_API = "REGISTER_API";
export const SIGN_IN_API_ERR = "SIGN_IN_API_ERR";
export const SIGN_IN_API = "SIGN_IN_API";
export const POST_ONBOARDING_STORE_ERR = "POST_ONBOARDING_STORE_ERR";
export const POST_ONBOARDING_STORE = "POST_ONBOARDING_STORE";
export const POST_SHAREHOLDER_STORE_ERR = "POST_SHAREHOLDER_STORE_ERR";
export const POST_SHAREHOLDER_STORE = "POST_SHAREHOLDER_STORE";
export const UPLOAD_DOCUMENT_ERR = "UPLOAD_DOCUMENT_ERR";
export const UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT";
export const COMPANY_DETAIL = "COMPANY_DETAIL";
export const COMPANY_DETAIL_ERR = "COMPANY_DETAIL_ERR";
export const CERTICATE_DETAIL_ERR = "CERTICATE_DETAIL_ERR";
export const CERTICATE_DETAIL = "CERTICATE_DETAIL";
export const DASHBOARD_DETAIL = "DASHBOARD_DETAIL";
export const DASHBOARD_DETAIL_ERR = "DASHBOARD_DETAIL_ERR";
export const DASHBOARD_COMPANIES_DETAIL = "DASHBOARD_COMPANIES_DETAIL";
export const DASHBOARD_COMPANIES_DETAIL_ERR = "DASHBOARD_COMPANIES_DETAIL_ERR";
export const DASHBOARD_COMPANY_DOC = "DASHBOARD_COMPANY_DOC";
export const DASHBOARD_COMPANY_DOC_ERR = "DASHBOARD_COMPANY_DOC_ERR";
export const FORGET_PASSWORD_SEND_ERR = "FORGET_PASSWORD_SEND_ERR";
export const FORGET_PASSWORD_SEND = "FORGET_PASSWORD_SEND";
export const OTP_VERIFICATION_ERR = "OTP_VERIFICATION_ERR";
export const OTP_VERIFICATION = "OTP_VERIFICATION";
export const RESET_PASSWORD_ERR = "RESET_PASSWORD_ERR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const VERIFICATION_DOC_ERR = "VERIFICATION_DOC_ERR";
export const VERIFICATION_DOC = "VERIFICATION_DOC";
export const INDIVIDUAL_DOC_ERR = "INDIVIDUAL_DOC_ERR";
export const INDIVIDUAL_DOC = "INDIVIDUAL_DOC";
export const PERSONAL_USER_ERR = "PERSONAL_USER_ERR";
export const PERSONAL_USER = "PERSONAL_USER";
export const PERSONAL_DOCUMENTS_ERR = "PERSONAL_DOCUMENTS_ERR";
export const PERSONAL_DOCUMENTS = "PERSONAL_DOCUMENTS";
export const REJECT_DOCUMENTS_ERR = "REJECT_DOCUMENTS_ERR";
export const REJECT_DOCUMENTS = "REJECT_DOCUMENTS";
export const REJECT_COMPANY_DOCUMENTS_ERR = "REJECT_COMPANY_DOCUMENTS_ERR";
export const REJECT_COMPANY_DOCUMENTS = "REJECT_COMPANY_DOCUMENTS";
