import React from 'react';
import GoogleLogin from 'react-google-login';
import banner1 from '../Assets/Business ethics-pana (1).svg'
import { FcGoogle } from "react-icons/fc";
import minilogo from '../Assets/Path 1350.svg'
import '../style/_dashboard.scss'
import Ellipse from '../Assets/Icon metro-user.svg'
import menu1 from '../Assets/Group 187.png'
import menu2 from '../Assets/Group 185.png'
import menu3 from '../Assets/Group 186.png'
import menu4 from '../Assets/Group 182.png'

class OnboardSidebar extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            search: '' ,
            password:'',
            email:'',
            errors:'',
            signIn:true

        };
    }
    

    

    handleRedirect=()=>{
        this.props.history.push("/SignUp");
    }
    handleChange=(e)=>{
        const data= e.target.name
        this.setState({
            [data]: e.target.value
        })
    }

    handleSignIn=()=>{
        this.props.history.push("/Dashboard");
    }

    handleRedirectMenu=(data)=>{
        this.props.history.push("/"+data);
    }

    render(){
        const data = window.location.href.split('/')[3]
        return(
            <div className="sideBar">
                <div className="row img-area">
                        <img src={Ellipse}/>
                        <p><div>{localStorage.getItem('username')}</div> <span>Blockchain Registry</span></p>
                        </div>
                <div className="menu_area">
                    <div className="row" style={{marginTop:'15',cursor:'pointer'}} >
                        {/* <img src={menu4}/> */}
                        <p style={{color:data === 'Dashboard' ? '#F0A500' : 'white',marginLeft:'10%'}}>Onboard your Company</p>
                    </div>
                    <div className="row" style={{cursor:'pointer'}} >
                        {/* <img src={menu2}/> */}
                        <p style={{color:data === 'Company_list' ? '#F0A500' : 'white',marginLeft:'10%'}}>Enter Details and Secure</p>
                    </div>
                    <div className="row" style={{cursor:'pointer'}}>
                        {/* <img src={menu3}/> */}
                        <p style={{color:data === 'Dashbytoard' ? '#F0A500' : 'white',marginLeft:'10%'}}>Create your Digital Identity</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default OnboardSidebar

