import { combineReducers } from 'redux';
import login from './Login/reducer';
import admin from './Admin/reducer';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';

const persistConfig = {
    key:'root',
    storage,
    whitelist:['admin']
}

const authPersist_admin_Config={key:'admin', storage: sessionStorage}


const rootReducer = combineReducers({

    admin:persistReducer(authPersist_admin_Config,admin),
});
export default persistReducer(persistConfig,rootReducer);

// export default combineReducers({
//     login,
//     admin
// });