import React from 'react';
import GoogleLogin from 'react-google-login';
import banner1 from '../Assets/Business ethics-pana (1).svg'
import { FiSearch } from "react-icons/fi";
import minilogo from '../Assets/Path 1350.svg'
import OnboardSideBar from '../Component/OnboardSidebar'
import { BsLink45Deg } from "react-icons/bs";
import { FaAngleDoubleLeft , FaAngleDoubleRight } from "react-icons/fa";
import one from '../Assets/Group 3.svg'
import two from '../Assets/Group 4 (1).svg'
import three from '../Assets/Group 5.svg'
import four from '../Assets/Group 6.svg'
import building from '../Assets/Group 11.svg'
import user from '../Assets/Group 12.svg'
import { ImCross } from "react-icons/im";
import pdf from '../Assets/Group 14.svg'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {onBoardCompany} from '../store/Admin/action'
import { ToastContainer, toast } from 'react-toastify';
import load from '../Assets/load'

class Onboard extends React.Component{
    constructor(props) {
        var today = new Date(),
        date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        super(props);
        this.state = {
            search: '' ,
            password:'',
            email:'',
            errors:'',
            signIn:true,
            file1: undefined,
            errors:'',
            currentDate: date,
            loadState: false,
        };
    }

    componentDidMount(){
        
    }

    handleChange=(e)=>{
        const data= e.target.name
        this.setState({
            [data]: e.target.value
        })
    }


    handlePassport=(e)=>{
        console.log(e.target.files[0])
        const name =e.target.name
        console.log(name)
        this.setState({
            [name]: e.target.files[0]
        })
    }

    cleanFile=(data)=>{
        const name= data
        this.setState({
            [name]: undefined
        })
    }
    handleValidation() {
        const errors = {};
        let formIsValid = true;
        
        if (this.state.CompanyName === undefined || this.state.CompanyName === '') {
            formIsValid = false;
            errors.CompanyName = 'This is a required field';
          }
          if (this.state.AuthorityName === undefined || this.state.AuthorityName === '') {
              formIsValid = false;
              errors.AuthorityName = 'This is a required field';
          }
          if (this.state.CurrentAttestation === undefined || this.state.CurrentAttestation === '') {
              formIsValid = false;
              errors.CurrentAttestation = 'This is a required field';
          }
          if (this.state.CountryOfIssuance === undefined || this.state.CountryOfIssuance === '') {
              formIsValid = false;
              errors.CountryOfIssuance = 'This is a required field';
          }

        this.setState({ errors });
        return formIsValid;
      }

    // handleSubmit=()=>{
    //     if(this.handleValidation()){
    //         this.props.history.push({pathname:'/Company_Onboard',state:{
    //             CompanyName:this.state.CompanyName,
    //             FinanceYear: this.state.FinanceYear,
    //             ProposedBank: this.state.ProposedBank,
    //             ActivitiesDmcc: this.state.ActivitiesDmcc,
    //             FacilityDmcc: this.state.FacilityDmcc,
    //             LegalStatus: this.state.LegalStatus,
    //             ShareCapital: this.state.ShareCapital
    //         }})
    //     }
    // }
    handleSubmit=()=>{
       
        if(this.handleValidation()){
            const body={
                company_name:this.state.CompanyName,
                authority_name: this.state.AuthorityName,
                current_attestation: this.state.CurrentAttestation,
                country_of_issuance: this.state.CountryOfIssuance,
            }
            this.props.onBoardCompany(body);
            this.setState({
                loadState: true,
            })
    
        }
    }

    componentDidUpdate(prev){
       
        if(prev.fail_update !== this.props.fail_update){
 
            this.setState({
                loadState: false,
            })

            if(!localStorage.getItem('refresh_token')){
                toast.error('Session Expired');
                this.props.history.push('/');
            }
            toast.error(this.props.fail_update.response.data.message);
        }
        if(prev.succ_update !==this.props.succ_update){
            console.log("compnayid", this.props.succ_update.data.data._id)
            this.props.history.push({pathname: '/Company_Onboard', state:{
                companyId: this.props.succ_update?.data?.data?._id
            }})
        }
    }

    render(){
        return(
            <div className='Onboard-page'>
                <div className='lg-page-banner'>
                    <OnboardSideBar {...this.props}/>
                </div>
                <div className='lg-page-form py-3'>
                    <div className='row first-row'>
                        <label>Onboarding Process</label>
                    </div>
                    <div className='row'>
                        <p style={{marginLeft:'1.5%'}}>Complete the Onboarding Process.</p>
                    </div>
                    <div className='row second-form'>
                        <div className='coling'>
                            <img src={one}/>
                        </div>
                        <div className='coling1'>
                            <p style={{color:'#5197cb'}}>Company Profile</p>
                        </div>
                        <div className='coling'>
                            <hr/>
                        </div>
                        <div className='coling'>
                            <img src={two}/>
                        </div>
                        <div className='coling1'>
                            <p>Share Holding</p>
                        </div>
                        <div className='coling'>
                            <hr/>
                        </div>
                        <div className='coling'>
                            <img src={three}/>
                        </div>
                        <div className='coling1'>
                            <p>Blockchain Documents</p>
                        </div>
                        {/* <div className='coling'>
                        <hr/>
                        </div>
                        <div className='coling'>
                            <img src={four}/>
                        </div>
                        <div className='coling1'>
                            <p>Verification</p>
                        </div> */}
                    </div>
                    <div className='row third-row'>
                        <div className='col-md-6'>
                            <img src={building}/><p>Company Profile <br/><span>Basic Details about company</span></p>
                        </div>
                        {/* <div className='col-md-6 add-user'>
                            <img src={user}/><a>Add New User Role</a>
                        </div> */}
                    </div>
                    <div className="row fourth-row">
                        <p>Basic Information Of Share holders</p>
                    </div>
                    <div className='row fivth-row'>
                        <div className='col-md-4'>
                            <label>Company Name <span style={{color:'red',marginLeft:'10px'}}>*</span></label>
                            <input type="text" value={this.state.CompanyName} name="CompanyName" onChange={this.handleChange} placeholder="Enter Company Name"/>
                            {this.state.errors.CompanyName !== undefined &&
                            <p style={{color:'red',fontSize:'13px',marginTop:'0px'}}>{this.state.errors.CompanyName}</p>
                            } 
                        </div>
                        <div className='col-md-4'>
                            <label>Authority Name <span style={{color:'red',marginLeft:'10px'}}>*</span></label>
                            <input type="text" value={this.state.AuthorityName} name="AuthorityName" onChange={this.handleChange} placeholder="Enter the Authority Name"/>
                            {this.state.errors.AuthorityName !== undefined &&
                            <p style={{color:'red',fontSize:'13px',marginTop:'0px'}}>{this.state.errors.AuthorityName}</p>
                            } 
                        </div>
                        <div className='col-md-4'>
                            <label>Current Attestations <span style={{color:'red',marginLeft:'10px'}}>*</span></label>
                            <input type="text" value={this.state.CurrentAttestation} name="CurrentAttestation" onChange={this.handleChange} placeholder="Enter the Current Attestations"/>
                            {this.state.errors.CurrentAttestation !== undefined &&
                            <p style={{color:'red',fontSize:'13px',marginTop:'0px'}}>{this.state.errors.CurrentAttestation}</p>
                            } 
                        </div>
                        <div className='col-md-4'>
                            <label>Country of Issuance <span style={{color:'red',marginLeft:'10px'}}>*</span></label>
                            <input type="text" value={this.state.CountryOfIssuance} name="CountryOfIssuance" onChange={this.handleChange} placeholder="Enter the Country of Issuance"/>
                            {this.state.errors.CountryOfIssuance !== undefined &&
                            <p style={{color:'red',fontSize:'13px',marginTop:'0px'}}>{this.state.errors.CountryOfIssuance}</p>
                            } 
                        </div>
                    </div>
                    <div className='row seventh-row'>
                        {this.state.loadState === false ?
                            <button onClick={this.handleSubmit}>Submit</button>
                        :
                        <img src={load} style={{width:'14%',marginLeft:'80%'}}/>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        succ_update: state.admin.onboardingStore,
        fail_update: state.admin.onboardingStore_err
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
      {
        onBoardCompany,
      },
      dispatch,
    )
  }


export default connect(mapStateToProps,mapDispatchToProps) (Onboard);

