import React from 'react';
import { FiSearch } from "react-icons/fi";
import SideBar from '../Component/SideBar'
import { BsLink45Deg } from "react-icons/bs";
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getDashboardCompaniesDetail,getCompanyDocuments} from '../store/Admin/action'

class SmartContractListScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            password: '',
            email: '',
            errors: '',
            companyList:[],
            signIn: true

        };
    }

    componentDidMount(){
        this.props.getDashboardCompaniesDetail();
    }

    componentDidUpdate(prev){

        if(prev.fail_dashboard_companies!==this.props.fail_dashboard_companies){
            console.log(prev.fail_dashboard_companies);
            console.log(this.props.fail_dashboard_companies)
            if(!localStorage.getItem('refresh_token')){
                this.props.history.push('/');
            }
        }

        if(prev.succ_dashboard_companies !== this.props.succ_dashboard_companies){
            console.log(this.props.succ_dashboard_companies?.data?.data);
            this.setState({companyList:this.props.succ_dashboard_companies?.data?.data});
        }        
    }

    handleCompanyDetail = (data) => {
        // localStorage.setItem('dmccId', data)
        this.props.history.push({
            pathname: "/Company_Detail", state: {
                data
            }
        });
        console.log(data);
    }

    render() {
        console.log(this.props)
        return (
            <div className='listed-page'>
                <div className='lg-page-banner'>
                    <SideBar {...this.props} />
                </div>
                <div className='lg-page-form py-3'>
                    <div className='row first-row'>
                        <label>Listed Smart Contracts</label>
                        {/* <input type="text" name="search" placeholder='Search for a Listed Company' />
                        <a href><FiSearch style={{ color: '#2f327d', marginTop: '-14px' }} /></a> */}
                    </div>
                    <div className='row'>
                        <p style={{ marginLeft: '1.5%' }}>Know your details in a quick overview,</p>
                    </div>

                    <div className='row third-row' >
                        <p>Smart Contract Details</p>
                        <hr></hr>
                        <div className="row" >
                            <div className="col-md-1 col">
                                <center>
                                    {/* <input type="checkbox" /> &nbsp;  */}
                                    <p>S.No</p>
                                </center>
                            </div>
                            <div className="col-md-2 col">
                                <center>
                                    <p>Company Name</p>
                                </center>
                            </div>
                            <div className="col-md-2 col">
                                <center>
                                    <p>Company ID</p>
                                </center>
                            </div>
                            <div className="col-md-2 col">
                                <center>
                                    <p>Onboarded On</p>
                                </center>
                            </div>
                            <div className="col-md-3 col">
                                <center>
                                    <p>Transaction ID</p>
                                </center>
                            </div>
                            <div className="col-md-2 col">
                                <center>
                                    <p>Verification Status</p>
                                </center>
                            </div>
                        </div>
                        <hr></hr>
                        {console.log(this.state.companyList)}

                        <div style={{height:'400px',overflowY:'scroll'}}> 
                            {Array.isArray(this.state.companyList)&&this.state.companyList.map((each,index)=>(
                            <>
                                <div className="row" style={{ cursor: 'pointer' }} onClick={() => this.handleCompanyDetail(each)}>
                                    <div className="col-md-1 col">
                                        <center>
                                            {/* <input type="checkbox" /> &nbsp;  */}
                                            <p>{index+1}</p>
                                        </center>
                                    </div>
                                    <div className="col-md-2 col">
                                        <center>
                                            <p>{each?.company_name}</p>
                                        </center>
                                    </div>
                                    <div className="col-md-2 col">
                                        <center>
                                            <p style={{ color: '#2f327d' }}>{each?.registry_id}</p>
                                        </center>
                                    </div>
                                    <div className="col-md-2 col">
                                        <center>
                                            <p>{each?.company_registration_date}</p>
                                        </center>
                                    </div>
                                    <div className="col-md-3 col">
                                        <center>
                                            <p style={{ color: '#2f327d', overflowWrap:"anywhere" }}>{each?.txID}</p>
                                        </center>
                                    </div>
                                    <div className="col-md-2 col">
                                        <center>
                                            <p><BsLink45Deg style={{color:each.isVerified?"#438d27":each.isMismatched?"#cc7a00":"#990000"}} />{each.isVerified?"Verified":each.isMismatched?"Mismatched":"Not verified"}</p>
                                        </center>
                                    </div>
                                </div>
                                <hr></hr>
                            </>
                            ))} 
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        succ_dashboard_companies:state.admin.dashboard_companies,
        fail_dashboard_companies:state.admin.dashboard_companies_err
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
      {
        getDashboardCompaniesDetail,
        getCompanyDocuments
      },
      dispatch,
    )
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(SmartContractListScreen)